import { computed, DestroyRef, effect, inject, Inject, Injectable, PLATFORM_ID, Signal, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Stripe } from 'stripe';
import { Observable, forkJoin, of, switchMap, take, tap } from 'rxjs';
import { AuthService } from '../auth-service/auth-service.service';
import { AfmStripeUserData } from 'src/app/shared/models/afmStripeUserData.models';
import { isPlatformServer } from '@angular/common';
import { environment } from 'src/environments/environment';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class AfmBeService {
  private baseUrl = environment.afmApis.be.baseUrl;
  afmStripeUserData = signal(new AfmStripeUserData());
  isLoadingUserData = signal<boolean>(false);
  userDataIsLoaded = signal<boolean>(false);
  userDataIsLoaded$ = toObservable(this.userDataIsLoaded);
  isLoadingProducts = signal<boolean>(false);
  products = signal<{ product: Stripe.Product; prices: Stripe.Price[] }[]>([]);
  activeProducts: Signal<{ product: Stripe.Product; prices: Stripe.Price[] }[]> = computed(() => this.products().filter((p) => p.product.active));

  private destroyRef = inject(DestroyRef);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    effect(() => {
      this.afmStripeUserData.set(new AfmStripeUserData());
      if (this.authService.user()) {
        this.updateAllUserData().then(() => {});
      }
    });

    this.isLoadingProducts.set(true);
    this.getAllProducts()
      .pipe(
        tap(() => {
          this.products.set([]);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((products) => {
        const filteredProducts = products
          .filter((x) => x.prices.filter((x) => x.type === 'recurring').length > 0)
          .map((p) => ({
            ...p,
            prices: [...p.prices].sort((a, b) => a.currency.localeCompare(b.currency)),
          }));
        this.products.set(filteredProducts);
        this.isLoadingProducts.set(false);
      });
  }

  updateAllUserData(): Promise<void> {
    this.isLoadingUserData.set(true);
    this.userDataIsLoaded.set(false);
    return new Promise((resolve, reject) => {
      this.getCustomers()
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          switchMap((customers) => {
            if (customers) {
              return forkJoin([
                this.getAllSubscriptions(customers.map((c) => c.id)).pipe(take(1)),
                this.getEntitlements(customers.map((c) => c.id)).pipe(take(1)),
              ]);
            }
            return of(null);
          }),
          take(1)
        )
        .subscribe({
          next: () => {
            this.userDataIsLoaded.set(true);
          },
          error: (err) => reject(err),
          complete: () => resolve(),
        })
        .add(() => this.isLoadingUserData.set(false));
    });
  }

  getLoadedProductById(productId: string): { product: Stripe.Product; prices: Stripe.Price[] } | undefined {
    return this.products().find((p) => p.product.id === productId);
  }

  createCheckout(priceId: string, token: string): Observable<Stripe.Checkout.Session> {
    const headers = { 'Turnstile-Token': token };
    return this.http.get<Stripe.Checkout.Session>(`${this.baseUrl}/api/checkout/${priceId}`, { headers }).pipe(take(1));
  }

  createPortalSession(customerId: string, token: string): Observable<Stripe.BillingPortal.Session> {
    const headers = { 'Turnstile-Token': token };
    return this.http.get<Stripe.BillingPortal.Session>(`${this.baseUrl}/api/portal/${customerId}`, { headers }).pipe(take(1));
  }

  getActiveProducts(): Observable<{ product: Stripe.Product; prices: Stripe.Price[] }[]> {
    const url = isPlatformServer(this.platformId) ? 'assets/ssg-files/active_products.json' : `${this.baseUrl}/api/products/active`;
    return this.http.get<{ product: Stripe.Product; prices: Stripe.Price[] }[]>(url).pipe(take(1));
  }

  getAllProducts(): Observable<{ product: Stripe.Product; prices: Stripe.Price[] }[]> {
    const url = isPlatformServer(this.platformId) ? 'assets/ssg-files/active_products.json' : `${this.baseUrl}/api/products/all`;
    return this.http.get<{ product: Stripe.Product; prices: Stripe.Price[] }[]>(url).pipe(take(1));
  }

  private getCustomers(): Observable<Stripe.Customer[]> {
    return this.http.get<Stripe.Customer[]>(`${this.baseUrl}/api/customers`).pipe(
      tap((customers) => {
        const currentData = this.afmStripeUserData();
        const newData = new AfmStripeUserData();
        newData.customers = customers;
        newData.subscriptions = currentData.subscriptions;
        newData.entitlements = currentData.entitlements;
        this.afmStripeUserData.set(newData);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  private getAllSubscriptions(customersIds: string[]): Observable<Stripe.Subscription[]> {
    return this.http.get<Stripe.Subscription[]>(`${this.baseUrl}/api/subscriptions/all?customersIds=${customersIds.join(',')}`).pipe(
      tap((subscriptions) => {
        const currentData = this.afmStripeUserData();
        const newData = new AfmStripeUserData();
        newData.subscriptions = subscriptions;
        newData.customers = currentData.customers;
        newData.entitlements = currentData.entitlements;
        this.afmStripeUserData.set(newData);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  private getEntitlements(customerIds: string[]): Observable<Stripe.Entitlements.ActiveEntitlement[]> {
    return this.http.get<Stripe.Entitlements.ActiveEntitlement[]>(`${this.baseUrl}/api/entitlements?customersIds=${customerIds.join(',')}`).pipe(
      tap((entitlements) => {
        const currentData = this.afmStripeUserData();
        const newData = new AfmStripeUserData();
        newData.entitlements = entitlements;
        newData.customers = currentData.customers;
        newData.subscriptions = currentData.subscriptions;
        this.afmStripeUserData.set(newData);
      }),
      takeUntilDestroyed(this.destroyRef)
    );
  }
}
